<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row>
          <v-col cols="10">
            <h4>Select Category</h4>
            <v-radio-group
              class="radio-group py-0 ma-0 px-1"
              small
              v-model="category1"
              row
            >
              <v-radio
                :disabled="formLoading"
                color="#2C1963"
                label="Wizfit"
                value="wizfit"
                @change="routeWizfit()"
                v-if="
                  this.subAdminAccess != 'school' && this.adminAccess != 'game'
                "
              ></v-radio>
              <v-radio
                :disabled="formLoading"
                color="#2C1963"
                label="Game"
                value="game"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="#38227A"
              dark
              class="px-11 text-capitalize ml-3"
              height="45px"
              style="border-radius: 10px; margin-top: 35px"
              @click="route()"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <span>Upload</span>
            </v-btn>
            <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item @click="route()">
                    <v-list-item-title>Upload</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="6" md="3" class="pl-3" style="margin-top: -35px">
            <v-autocomplete
              label="Category"
              outlined
              dense
              v-model="category"
              :items="categoryList"
              item-value="id"
              item-text="category_name"
              class="formFields"
              color="#7253CF"
              @change="getResourceData()"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        fixed-header
        hide-default-footer
        :headers="resourceDataTable.headers"
        :items="resourceDataTable.items"
        :loading="resourceDataTable.loading"
        :items-per-page="
          resourceDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          resourceDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">{{ props.item.title }}</div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">{{ props.item.file_name }}</div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">{{ props.item.notes }}</div>
            </td>
            <td class="text-center row-item">
              <a @click="videoURL(props.item.video_url)" target="_blank">{{
                props.item.video_url
              }}</a>
            </td>
            <td class="text-center row-item">
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="editResource(props.item)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>

              <v-btn
                @click="getReport(props.item.id)"
                class="actionColumn-btn"
                color="#F2F2F2"
                :loading="btnLoading && indexClicked === props.item.id"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="25"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download </span>
                </v-tooltip>
              </v-btn>
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleDeleteGameResourceModal({
                    Delete: true,
                    id: props.item.id,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-chip>
              <!--    <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleResourcePreviewModal({ show: true, id: props.item.id })"
         >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Preview</span>
              </v-tooltip>
            </v-chip>-->

              <!--   <a :href="props.item.document" target="_blank">Preview</a>-->
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-card class="footer-card">
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    resourceDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  resourceDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  resourceDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  resourceDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  resourceDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--<v-card class="mt-10">
        <v-card-title class="py-3">
        <v-row justify="center">
          <v-col> </v-col>
        </v-row>
      </v-card-title>
    <v-card-text>
        <v-row>
          <div class="card-text">
            <p class="heading" style="margin-left: 11px; margin-top: 27px">
              Upload File
            </p>
          </div>
          <br />
        </v-row>
        <v-row>
          <v-col cols="3" class="px-2">
            <v-text-field
              outlined
              dense
              v-model="title"
              label="Title"
              color="#7253CF"
              class="formFields"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="px-2">
            <v-text-field
              outlined
              dense
              v-model="note"
              label="Note"
              color="#7253CF"
              class="formFields"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="pt-8 px-6"> </v-col>
          <v-col cols="6">
             <label style="font-size: medium;">Upload File</label>
            <v-file-input
              v-model="file"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              outlined
              
              color="#7254CF"
              dense
              clearable
              :disabled="formLoading"
            >
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              dark
              style="border-radius: 10px"
              class="mr-4 text-capitalize px-16"
              color="#38227A"
              height="45"
              @click="submitBulkUpload"
              :loading="submitLoading"
            >
              <span>Submit</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>-->
    <game-resource-preview-modal></game-resource-preview-modal>
    <delete-game-resource-modal
      @click="deleteData()"
    ></delete-game-resource-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import {
  API_UPLOAD_GAME_RESOURCE,
  GET_RESOURCES_CATEGORYLIST,
  API_GET_GAME_RESOURCE,
  API_DOWNLOAD_GAME_RESOURCE,
} from "@/constants/APIUrls";
import {
  //   DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  RESOURCE_LIST,
  DATA,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "Upload",
  components: {
    DeleteGameResourceModal: () =>
      import("../DeleteResourceModal/DeleteResourceModal.vue"),
    GameResourcePreviewModal: () =>
      import("./GameResourcePreviewModal/GameResourcePreviewModal"),
  },
  data() {
    return {
      subAdminAccess: localStorage.getItem("subadminAccess"),
      adminAccess: localStorage.getItem("adminAccess"),
      previewFlag: false,
      file: {},
      categoryList: [],
      routeName: "",
      user_id: localStorage.getItem("user_id"),
      note: "",
      title: "",
      category: 1,
      category1: "game",
      formLoading: false,
      submitLoading: false,
      items: [],
      btnLoading: false,
      indexClicked: undefined,
      resourceDataTable: {
        loading: false,
        headers: [
          {
            sortable: false,
            text: "Title",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "File Name",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "Notes",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            sortable: false,
            text: "URL",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "16%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
  },
  created() {
    this.debounceGetResourceData = _.debounce(this.getResourceData, 500);
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "resourceDataTable.page_info.page_number": function () {
      this.debounceGetResourceData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "resourceDataTable.page_info.detail_count": function () {
      this.getResourceData();
    },
    //Watcher for detecting change in SEARCH
    "resourceDataTable.search": function () {
      this.debounceGetResourceData();
    },
    //Watcher for detecting Change in filter by date
  },
  methods: {
    ...mapActions({
      toggleDeleteGameResourceModal:
        "gameManagement/toggleDeleteGameResourceModal",
      toggleResourcePreviewModal: "gameManagement/togglePreviewModal",

      showToast: "snackBar/showToast",
    }),
    getCategoryList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.categoryList = data.category_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson.search = "game";
      return Axios.request_GET(
        GET_RESOURCES_CATEGORYLIST,

        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    submitBulkUpload() {
      const self = this;
      this.submitLoading = true;
      const successHandler = (res) => {
        self.formLoading = false;
        console.log(res);
        this.showToast({
          message: "File uploaded successfully.",

          color: "s",
        });
        this.file = {};
        location.reload();
        this.submitLoading = false;
      };
      const failureHandler = (res) => {
        this.submitLoading = false;
        console.log(res.data);
        this.showToast({
          message: "something went wrong",
          color: "e",
        });
      };
      let formData = new FormData();
      if (this.file instanceof File) {
        formData.append("document", this.file);
        formData.append("notes", this.note);
        formData.append("title", this.title);
        formData.append("user", this.user_id);
        formData.append("category", this.category);
        Axios.request_POST(
          API_UPLOAD_GAME_RESOURCE,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    getResourceData() {
      const self = this;
      self.resourceDataTable.loading = true;
      console.log("in get student data");
      console.log(this.layout);

      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "RESOURCE_LIST: ",
          res[DATA][RESOURCE_LIST],
          res.data
        );
        self.resourceDataTable.items = res[DATA].resource_list;
        self.resourceDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.resourceDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.resourceDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        self.resourceDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        self.resourceDataTable.loading = false;
      };
      let dataTableParams = {};
      if (this.resourceDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.resourceDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.resourceDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.resourceDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams.category_id = this.category;
      Axios.request_GET(
        API_GET_GAME_RESOURCE,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getReport(id) {
      const self = this;
      self.btnLoading = true;
      this.indexClicked = id;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      formData["id"] = id;
      Axios.request_GET_BLOB(
        API_DOWNLOAD_GAME_RESOURCE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    deleteData() {
      this.deleteResource();
    },
    deleteResource() {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;

        this.showToast({
          message: "Record deleted",
          color: "s",
        });
        this.getResourceData();
      };
      const failureHandler = () => {
        self.formLoading = false;
      };
      let formData = {};
      console.log(formData);
      Axios.request_DELETE(
        "/game/game_resource/" +
          this.$store.state.gameManagement.deleteGameResourceModal
            .gameResourceID,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    showPreview() {
      this.previewFlag = true;
    },
    routeWizfit() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.resourceManagement.name,
      });
    },
    route() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.gameresourceUpload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
    editResource(item) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.gameresourceUpload.name,
        query: { id: item.id },
      });
    },
    videoURL(row) {
      window.open(row, "_blank");
    },
  },
  mounted() {
    this.getCategoryList();
    this.getResourceData();
  },
  beforeDestroy() {
    this.resetRoute();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 90px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 160px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    /** min-width: 1200px; */
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

* {
  font-family: Lato;
}
.v-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.card-text {
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.paragraph {
  margin-top: 5px;
  font-size: 17px;
}
.v-card__title {
  text-align: center;
  background-color: #d30024;
  color: white;
  min-height: 50px;
  font-family: Roboto Slab;
}
.v-input {
  background: #ffffff;
  border-radius: 6px;
}
.v-btn >>> span {
  font-size: 17px;
  font-weight: 600;
}
.btn.text-right.px-2.col-sm-3.col-12 {
  padding-top: 50px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}

@media (min-width: 0px) and (max-width: 600px) {
  .table-div {
    /**  margin-top: 100px; */
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .btn.text-right.px-2.col-sm-3.col-12 {
    padding-top: 0px;
  }
  .card-heading {
    position: absolute;
    left: 130px;
  }
  .card.v-card {
    max-height: 300px;
  }
  .btn {
    position: static;
  }
  .btn1 {
    position: static;
  }
  .v-input {
    margin-bottom: 20px;
  }
}
</style>
