<template>
  <div>
    <v-form>
      <v-row style="margin-left: 20px; margin-top: 25px">
        <v-col cols="4" class="pl-2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                v-model="formatedStartDate"
                prepend-inner-icon="mdi-calendar-range"
                label="Month"
                v-bind="attrs"
                readonly
                color="#7253CF"
                class="formFields"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="start_date"
              no-title
              @input="menu = false"
              type="month"
              @change="getEventSlotList()"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <span v-if="this.slotList.length == 0" style="margin-left: 40%"
        >No data available</span
      >
      <div
        v-for="monthSlot in slotList"
        :key="monthSlot.date"
        style="border-bottom: 1px solid black; margin-left: 50px"
      >
        <br />
        <span style="font-size: 19px; font-weight: bold; width: 30%"
          >{{ monthSlot.date_str }}
          <v-icon
            dark
            v-bind="attrs"
            v-on="on"
            class="icons"
            color="red"
            size="25"
            style="margin-left: 2%"
            @click="
              toggleDeletePlayerEventModal({
                Delete: true,
                id: monthSlot.slot.id,
              })
            "
          >
            mdi-delete
          </v-icon>
          <v-btn
            color="#38227A"
            dark
            class="px-2"
            @click="
              togglePlayerAvailabilityEventModal({
                show: true,
                type: 'edit',
                date: monthSlot.slot.date,
                id: monthSlot.slot.id,
              })
            "
            style="margin-left: 25px"
            v-if="monthSlot.slot.details.length != 0"
            ><span style="margin-left: 5px; text-transform: capitalize"
              >Edit</span
            ></v-btn
          >

          <v-btn
            color="#38227A"
            dark
            class="px-2"
            @click="
              togglePlayerAvailabilityEventModal({
                show: true,
                type: 'add',
                date: monthSlot.slot.date,
                id: monthSlot.slot.id,
              })
            "
            style="margin-left: 25px"
            v-if="monthSlot.slot.details.length == 0"
            >Add</v-btn
          >

          <div
            v-for="detail in monthSlot.slot.details"
            :key="detail.id"
            style="font-size: 15px; font-weight: 400"
          >
            <div v-if="detail.note !== null" style="font-size: 17px">
              {{ detail.note }}
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: bold;
              color: #7253cf;
              margin-left: -2px;
            "
          >
            (Available Slots-{{ monthSlot.slot.empty_slots }})
          </div>
        </span>
        <br />
        <hr />

        <!--    <span
                v-for="info1 in slot.slot_info.players" :key="info1.id"
               >
  <span v-if="info1.players !== null" style="font-size: 17px;margin-left: 40%;">
                    {{ info1}}
                  </span>
                  </span>-->
      </div>
    </v-form>
    <player-availability-event></player-availability-event>
    <delete-player-event-modal></delete-player-event-modal>
  </div>
</template>
<script>
import { API_PLAYER_AVAILBLE_MONTHLY_SLOT_LIST } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "Upload",
  components: {
    PlayerAvailabilityEvent: () =>
      import("../PlayerAvailability/PlayerAvailabilityEvent.vue"),
    DeletePlayerEventModal: () =>
      import("../DeletePlayerEventModal/DeletePlayerEventModal.vue"),
  },
  data() {
    return {
      start_date: "",
      slotList: [],
      menu: false,
    };
  },
  computed: {
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("MMMM");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  created() {},

  methods: {
    ...mapActions({
      // calendar Modal
      togglePlayerAvailabilityEventModal:
        "calendarManagement/togglePlayerAvailabilityEvent",
      toggleDeletePlayerEventModal:
        "calendarManagement/toggleDeletePlayerEventModal",
      showToast: "snackBar/showToast",
    }),
    getEventSlotList() {
      const self = this;
      const successHandler = (res) => {
        self.slotList = res.data.monthly_slots;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      //  formJson["start_date"] = this.start_date;
      formJson["month"] = this.start_date.split("-")[1] || "";
      formJson["year"] = this.start_date.split("-")[0] || "";
      return Axios.request_GET(
        API_PLAYER_AVAILBLE_MONTHLY_SLOT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  mounted() {
    this.getEventSlotDetailList();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 90px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 160px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    /** min-width: 1200px; */
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

* {
  font-family: Lato;
}
.v-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.card-text {
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.paragraph {
  margin-top: 5px;
  font-size: 17px;
}
.v-card__title {
  text-align: center;
  background-color: #d30024;
  color: white;
  min-height: 50px;
  font-family: Roboto Slab;
}
.v-input {
  background: #ffffff;
  border-radius: 6px;
}
.v-btn >>> span {
  font-size: 17px;
  font-weight: 600;
}
.btn.text-right.px-2.col-sm-3.col-12 {
  padding-top: 50px;
}
.textHeading {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}

@media (min-width: 0px) and (max-width: 600px) {
  .table-div {
    /**  margin-top: 100px; */
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .btn.text-right.px-2.col-sm-3.col-12 {
    padding-top: 0px;
  }
  .card-heading {
    position: absolute;
    left: 130px;
  }
  .card.v-card {
    max-height: 300px;
  }
  .btn {
    position: static;
  }
  .btn1 {
    position: static;
  }
  .v-input {
    margin-bottom: 20px;
  }
}
</style>
